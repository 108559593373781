import printReportAction from '@/components/nsw-components/PrintReportsAction';
import DocumentNewVersionAction from '@/components/nsw-components/DocumentNewVersionAction';

export default {
	actions: [
		printReportAction.printReport('PASSENGER_LIST', 'imofalformid', 'reports.label.falForms.passengerList'),
		printReportAction.printReport('VEHICLES_LIST', 'imofalformid', 'reports.label.falForms.vehicleList'),
		DocumentNewVersionAction.newVersion({
			imofalformtypeid: 6
		})
	]
};
