<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs" :externalFilter="filter()"></pui-datatable>
		<doc-new-version-modal :modelName="modelName" :pkAttribute="pkAttribute"></doc-new-version-modal>
	</div>
</template>

<script>
import imofalform6Actions from './ImoFalForm6Actions';
import docNewVersionModal from '@/components/nsw-components/DocumentNewVersionModal';

export default {
	name: 'imofalform6menu-grid',
	components: {
		DocNewVersionModal: docNewVersionModal
	},
	data() {
		return {
			modelName: 'imofalform6menu',
			pkAttribute: 'imofalformid',
			actions: imofalform6Actions.actions,
			modelColumnDefs: {
				indarrivaldeparture: {
					render: (data) => {
						if (data === '1') {
							return this.$t('grid.imofalform6menu.isarrival');
						} else if (data === '2') {
							return this.$t('grid.imofalform6menu.isdeparture');
						} else {
							return '';
						}
					}
				}
			}
		};
	},
	methods: {
		filter() {
			let group1 = {
				groupOp: 'and',
				groups: [],
				rules: [{ field: 'statusid', op: 'ne', data: '1' }]
			};

			let groups = [group1];
			if (this.$store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_RELATED_ORGS) {
				this.$store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_RELATED_ORGS.forEach((org) => {
					groups.push({
						groupOp: 'and',
						groups: [],
						rules: [
							{ field: 'statusid', op: 'eq', data: '1' },
							{
								field: 'creausercompany',
								op: 'cn',
								data: org
							},
							{
								field: 'creausercompany',
								op: 'nn'
							}
						]
					});
				});
			}

			const filter = { groupOp: 'or', rules: [], groups: groups };

			return filter;
		}
	}
};
</script>
